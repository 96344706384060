<template>
	<div class="">
		<validation-observer ref="formRules">
			<b-card no-body>
				<b-card-body>
					<b-form>
						<b-row>
							<b-col md="6" cols="12">
								<b-form-group label="Project" label-for="projectId">
									<validation-provider #default="{ errors }" name="Project" rules="required">
										<v-select
											id="projectId"
											v-model="formData.project_id"
											clearable
											filterable
											searchable
											:options="projects"
											:reduce="(o) => o.id"
											placeholder="Select a project"
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</b-form-group>
							</b-col>
							<b-col md="6" cols="12">
								<b-form-group label="Quantities" label-for="quanities">
									<b-form-input id="quanities" v-model="formData.quantity" type="number"></b-form-input>
								</b-form-group>
							</b-col>
							<b-col md="6" cols="12">
								<b-form-group label="Unit" label-for="units">
									<b-form-input id="units" v-model="formData.unit"></b-form-input>
								</b-form-group>
							</b-col>
							<b-col md="6" cols="12">
								<b-form-group>
									<label>تاريخ الانتهاء المتوقع</label>
									<validation-provider #default="{ errors }" name="End date" rules="required">
										<b-input-group>
											<b-form-input
												v-model="formData.end_date"
												type="text"
												autocomplete="off"
												show-decade-nav
												readonly
											/>
											<b-input-group-append>
												<b-form-datepicker
													v-model="formData.end_date"
													show-decade-nav
													button-only
													right
													locale="en-US"
													@context="onContext"
												/>
											</b-input-group-append>
										</b-input-group>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</b-form-group>
							</b-col>
							<b-col md="6" cols="12">
								<b-form-group>
									<label>تاريخ العقد المتوقع</label>
									<validation-provider #default="{ errors }" name="Contract date" rules="required">
										<b-input-group>
											<b-form-input
												v-model="formData.contract_date"
												type="text"
												autocomplete="off"
												show-decade-nav
												readonly
											/>
											<b-input-group-append>
												<b-form-datepicker
													v-model="formData.contract_date"
													show-decade-nav
													button-only
													right
													locale="en-US"
													@context="onContext"
												/>
											</b-input-group-append>
										</b-input-group>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</b-form-group>
							</b-col>
							<b-col md="6" cols="12">
								<b-form-group>
									<label>تاريخ الاعلان المتوقع</label>
									<validation-provider #default="{ errors }" name="Announce date" rules="required">
										<b-input-group>
											<b-form-input
												v-model="formData.announce_date"
												type="text"
												autocomplete="off"
												show-decade-nav
												readonly
											/>
											<b-input-group-append>
												<b-form-datepicker
													v-model="formData.announce_date"
													show-decade-nav
													button-only
													right
													locale="en-US"
													@context="onContext"
												/>
											</b-input-group-append>
										</b-input-group>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</b-form-group>
							</b-col>
							<b-col md="6" cols="12">
								<b-form-group label="Disburse" label-for="units">
									<b-form-input id="units" v-model="formData.disburse" type="number"></b-form-input>
								</b-form-group>
							</b-col>
							<b-col md="6" cols="12">
								<b-form-group label="Male beneficiraies count" label-for="units">
									<b-form-input id="units" v-model="formData.male_count" type="number"></b-form-input>
								</b-form-group>
							</b-col>
							<b-col md="6" cols="12">
								<b-form-group label="Female beneficiraies count" label-for="units">
									<b-form-input id="units" v-model="formData.female_count" type="number"></b-form-input>
								</b-form-group>
							</b-col>
							<b-col md="6" cols="12">
								<b-form-group label="Number of direct female labors" label-for="units">
									<b-form-input id="units" v-model="formData.female_labors" type="number"></b-form-input>
								</b-form-group>
							</b-col>
							<b-col md="6" cols="12">
								<b-form-group label="Number of direct labor from host communities" label-for="units">
									<b-form-input id="units" v-model="formData.labors_from_community" type="number"></b-form-input>
								</b-form-group>
							</b-col>
							<b-col md="6" cols="12">
								<b-form-group label="Number of direct labor from outside communities" label-for="units">
									<b-form-input id="units" v-model="formData.labors_from_outside" type="number"></b-form-input>
								</b-form-group>
							</b-col>
							<b-col md="6" cols="12">
								<b-form-group label="Number of direct labor of IDPs & Returnes" label-for="units">
									<b-form-input id="units" v-model="formData.labors_from_idps" type="number"></b-form-input>
								</b-form-group>
							</b-col>
						</b-row>

						<hr />
						<h4 class="card-title mt-1 mb-50">Farmars</h4>

						<b-row>
							<b-col md="6" cols="12">
								<b-form-group label="Number of farmars benefiting" label-for="project-code">
									<b-form-input v-model="formData.farmars" type="number"></b-form-input>
								</b-form-group>
							</b-col>
						</b-row>

						<hr />
						<h4 class="card-title mt-1 mb-50">Education</h4>

						<b-row>
							<b-col md="6" cols="12">
								<b-form-group label="Total number of students" label-for="project-code">
									<b-form-input v-model="formData.total_students" type="number"></b-form-input>
								</b-form-group>
							</b-col>
							<b-col md="6" cols="12">
								<b-form-group label="Female students" label-for="project-code">
									<b-form-input v-model="formData.female_students" type="number"></b-form-input>
								</b-form-group>
							</b-col>
							<b-col md="6" cols="12">
								<b-form-group label="Number of students from the host communities" label-for="project-code">
									<b-form-input v-model="formData.students_form_community" type="number"></b-form-input>
								</b-form-group>
							</b-col>
							<b-col md="6" cols="12">
								<b-form-group label="Number of students (IDPs)" label-for="project-code">
									<b-form-input v-model="formData.students_form_outside" type="number"></b-form-input>
								</b-form-group>
							</b-col>
						</b-row>
					</b-form>
				</b-card-body>
				<b-card-footer class="d-flex justify-content-end">
					<b-button variant="primary" @click="save">Save</b-button>
				</b-card-footer>
			</b-card>
		</validation-observer>
	</div>
</template>

<script>
import {
	BCard,
	BCardFooter,
	BButton,
	BCardBody,
	BFormInput,
	BInputGroupAppend,
	BFormGroup,
	BForm,
	BRow,
	BCol,
	BFormDatepicker,
	BInputGroup
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import vSelect from 'vue-select';
import store from '@/store';
import { required } from '@validations';

export default {
	name: 'Indicators',

	components: {
		ValidationProvider,
		ValidationObserver,
		BCard,
		BCardFooter,
		BButton,
		BCardBody,
		BFormInput,
		BFormGroup,
		BInputGroupAppend,
		BInputGroup,
		BForm,
		BRow,
		BCol,
		BFormDatepicker,
		vSelect
	},

	data: () => ({
		formData: {
			project_id: '',
			unit: '',
			end_date: '',
			announce_date: '',
			contract_date: '',
			quantity: '',
			disburse: '',
			male_count: '',
			female_count: '',
			female_labors: '',
			labors_from_community: '',
			labors_from_outside: '',
			labors_from_idps: '',
			farmars: '',
			total_students: '',
			female_students: '',
			students_form_community: '',
			students_form_outside: ''
		},

		formatted: '',
		selected: '',

		required,
		projects: []
	}),

	async created() {
		const data = await store.dispatch('projects/getProjectShortList');
		this.projects = data.map((p) => ({
			label: `${p.SP_TITLE} - ${p.SP_PROJID}`,
			id: p.SP_PROJID
		}));

		if (this.$route.params.id) {
			const indicator = await this.loadIndicator();

			const keysToExeclude = ['announce_date', 'end_date', 'unit', 'contract_date', 'project_id', 'id'];
			Object.keys(this.formData).forEach((key) => {
				if (!keysToExeclude.includes(key)) {
					this.formData[key] = indicator[key]?.target ?? 0;
				} else if (key.split('_')[1] === 'date') {
					this.formData[key] = indicator[key].date;
				} else {
					this.formData[key] = indicator[key];
				}
			});

			this.formData.id = indicator.id;
		}
	},

	methods: {
		onContext(ctx) {
			this.formatted = ctx.selectedFormatted;
			this.selected = ctx.selectedYMD;
		},

		async save() {
			const success = await this.$refs.formRules.validate();
			if (!success) return;

			if (!this.formData.id) {
				await store.dispatch('indicators/addIndicator', {
					...this.formData
				});
			} else {
				await store.dispatch('indicators/editIndicator', {
					indicator: { ...this.formData },
					id: this.formData.id
				});
			}

			this.$router.back();
		},

		async loadIndicator() {
			const hasIndicators = !!store.state.indicators.indicators.length;

			const indicator = hasIndicators
				? store.getters['indicators/getIndicator'](this.$route.params.id)
				: await store.dispatch('indicators/getIndicator', this.$route.params.id);

			return indicator;
		}
	}
};
</script>

<style lang="scss">
.b-form-datepicker .btn {
	padding: 0.65rem 1rem;
	border-radius: 0 4px 4px 0;
}
</style>
